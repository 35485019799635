<template>
  <div class="rural">
    <HeaderHot :title="'乡村振兴'" :currentIndex="5"></HeaderHot>
    <Breadcrumb :navTitle="'乡村振兴'"></Breadcrumb>
    <div class="rural-top" @click="goRuraldetails(itemData.topList[0].id)">
      <img :src="BaseUrl + itemData.topList[0].image" alt="" />
      <div class="top-right">
        <span :title="itemData.topList[0].title">{{
          itemData.topList[0].title
        }}</span>
        <div>
          <span :title="itemData.topList[0].brief">{{
            itemData.topList[0].brief
          }}</span>
          <span>[详细]</span>
        </div>
      </div>
    </div>
    <div class="rural-content">
      <div class="rural-left">
        <div class="rural-item">
          <div
            class="item-list"
            v-for="item in itemList"
            :key="item.id"
            @click="goRuraldetails(item.id)"
          >
            <span :title="item.title">{{ item.title }}</span>
            <div>
              <span :title="item.brief">{{ item.brief }}</span>
              <span>[详细]</span>
            </div>
            <div>
              <span>{{ item.createtime }}</span>
              <span>来源: {{ item.source }}</span>
            </div>
          </div>
        </div>
        <div class="pageDown">
          <el-pagination
            background
            prev-text="上一页"
            next-text="下一页"
            @prev-click="pagePrev"
            @next-click="pageNext"
            @current-change="pageChange"
            layout=" prev, pager, next"
            page-size="8"
            :total="totalPage"
          >
          </el-pagination>
        </div>
      </div>
      <div class="rural-right">
        <div class="rural-item">
          <div
            class="list-item"
            v-for="item in itemData.tuiList"
            :key="item.id"
            @click="goRuraldetails(item.id)"
          >
            <div>
              <img :src="BaseUrl + item.image" alt="" />
              <span :title="item.title">{{ item.title }}</span>
            </div>
            <div>
              <span :title="item.brief">{{ item.brief }}</span>
              <span>[详细]</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import HeaderHot from "../../../components/HeaderHot.vue";
import Breadcrumb from "../../../components/Breadcrumb.vue";
import Footer from "../../../components/Footer.vue";
export default {
  name: "Rural",
  data() {
    return {
      page: 1,
      pageSize: 8,
      totalPage: 0,
      itemData: [],
      itemList: [],
    };
  },
  created() {
    this.getData();
    this.getList();
  },
  methods: {
    // 获取乡村页面数据
    getData() {
      this.$http({
        url: "/lilun/index",
      }).then((res) => {
        console.log(res, "res");
        this.itemData = res.data;
      });
    },
    created() {
      this.getData();
      this.getList();
    },
    // 获取乡村列表
    getList(page) {
      console.log(page, "page");
      this.$http({
        url: "/lilun/list",
        params: {
          page: page ? page : this.page,
          pagesize: this.pageSize,
        },
      }).then((res) => {
        console.log(res, "res");
        this.itemList = res.data.list;
        this.totalPage = res.data.total;
      });
    },
    // 上一页
    pagePrev() {
      this.page--;
      this.getList(this.page);
    },
    // 下一页
    pageNext() {
      console.log("下一页");
      this.page++;
      this.getList(this.page);
    },
    // 页面改变
    pageChange(e) {
      console.log(e);
      this.page = e;
      this.getList(this.page);
    },
    // 跳转乡村振兴
    goRuraldetails(id) {
      this.$router.push(`/ruraldetails/${id}`);
    },
  },
  components: {
    HeaderHot,
    Breadcrumb,
    Footer,
  },
};
</script>

<style lang="less" scoped>
.rural {
  .rural-top {
    display: flex;
    width: 1200px;
    margin: 0 auto 60px;
    > img:nth-child(1) {
      width: 560px;
      height: 332px;
    }
    > div:nth-child(2) {
      width: 640px;
      height: 332px;
      padding: 20px 0 0 40px;
      box-sizing: border-box;
      background-color: #eeeeee;
      > span:nth-child(1) {
        font-size: 22px;
        color: #333333;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      > span:nth-child(1):hover {
        text-decoration: underline;
      }
      > div:nth-child(2) {
        margin-top: 30px;
        font-size: 14px;
        > span:nth-child(1) {
          color: #333333;
        }
        > span:nth-child(2) {
          color: #2094e1;
          cursor: pointer;
        }
      }
    }
  }
  .rural-content {
    display: flex;
    width: 1200px;
    margin: 0 auto -150px;
    .rural-left {
      width: 664px;
      margin-right: 36px;
      .rural-item {
        margin-bottom: 40px;
        .item-list {
          width: 664px;
          height: 138px;
          padding: 30px 0 11px 0;
          //    margin-bottom: 11px;
          border-bottom: 2px dashed #b5b5b5;
          > span:nth-child(1) {
            display: block;
            font-size: 19px;
            color: #333333;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          > span:nth-child(1):hover {
            text-decoration: underline;
          }
          > div:nth-child(2) {
            margin: 15px 0 25px 0;
            font-size: 14px;
            color: #2094e1;
            > span:nth-child(1) {
              color: #666666;
              text-overflow: -o-ellipsis-lastline;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              line-clamp: 2;
              -webkit-box-orient: vertical;
            }
            > span:nth-child(2) {
              cursor: pointer;
            }
          }
          > div:nth-child(3) {
            display: flex;
            justify-content: space-between;
            padding: 0 65px 0 90px;
            font-size: 12px;
            color: #999999;
          }
        }
        > div:first-child {
          border-top: 2px dashed #b5b5b5;
        }
      }
      .pageDown {
        width: 664px;
        margin: 0px auto 80px;
        text-align: center;
      }
    }
    .rural-right {
      width: 500px;
      .rural-item {
        width: 500px;
        .list-item {
          margin-bottom: 55px;
          > div:nth-child(1) {
            position: relative;
            width: 500px;
            height: 300px;
            > img:nth-child(1) {
              position: absolute;
              width: 500px;
              height: 300px;
            }
            > span:nth-child(2) {
              width: 500px;
              height: 40px;
              position: absolute;
              bottom: 0;
              text-align: left;
              text-indent: 1.5em;
              line-height: 40px;
              background: rgba(100, 100, 100, 0.33);
              color: #ffffff;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              text-indent: 0.5em;
            }
          }
          > div:nth-child(2) {
            margin-top: 32px;
            font-size: 14px;
            color: #666666;
            > span:nth-child(1) {
              text-overflow: -o-ellipsis-lastline;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 8;
              line-clamp: 8;
              -webkit-box-orient: vertical;
            }
            > span:nth-child(2) {
              font-size: 16px;
              line-height: 25px;
              color: #2050ac;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
</style>
